<script>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useUser } from '/~/composables/user'
import { useMembership } from '/~/templates/bill-payments/composables'
import TierModal from '/~/templates/bill-payments/modals/tier/tier-modal.vue'

export default {
  name: 'ewallet-points-and-tier',
  components: {
    BaseAction,
    BaseIcon,
    TierModal,
  },
  props: {
    label: {
      type: String,
      default: 'Manage program tier',
    },
    icon: {
      type: String,
      default: 'points/token',
    },
    bgColor: {
      type: String,
      default: '#FFFFFF',
    },
    iconColor: {
      type: String,
      default: 'var(--color-primary)',
    },
    textColor: {
      type: String,
      default: '#1F2937',
    },
    action: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter()
    const { isMembershipSwitchingEnabled } = useMembership()
    const { user } = useUser()

    const showTierModal = ref(false)

    const hasAction = computed(() => props.action?.type !== 'none')

    function onTierModalHide() {
      showTierModal.value = false
      router.push({ hash: '#profile-home' })
    }

    emitter.on('ewallet:toggle-tier-modal', () => {
      if (!showTierModal.value && !isMembershipSwitchingEnabled.value) {
        return
      }

      showTierModal.value = !showTierModal.value
    })

    return {
      user,
      isMembershipSwitchingEnabled,
      showTierModal,
      hasAction,
      onTierModalHide,
    }
  },
  watch: {
    '$route.query': {
      handler(value) {
        if (!this.isMembershipSwitchingEnabled) {
          return
        }

        this.showTierModal = Boolean(value?.tier)
      },
      immediate: true,
    },
  },
}
</script>

<template>
  <div>
    <component
      :is="hasAction ? 'base-action' : 'div'"
      v-bind="action"
      :type="action && action.type ? action.type : 'none'"
      class="block w-full rounded-md border py-3 transition duration-150"
      :class="{
        'cursor-pointer hover:shadow': hasAction,
        'pointer-events-none': !isMembershipSwitchingEnabled,
      }"
      appearance=""
      :style="{
        backgroundColor: bgColor || '#FFFFFF',
        color: textColor || '#1F2937',
      }"
    >
      <template #entry>
        <div class="flex w-full items-center gap-x-4 px-4">
          <base-icon
            v-if="icon"
            :svg="icon"
            :size="40"
            class="shrink-0 text-primary"
            :style="{
              color: iconColor,
            }"
          />
          <span class="text-sm font-bold">
            <span
              :class="{
                'mr-3 border-r pr-3':
                  Boolean(label) && isMembershipSwitchingEnabled,
              }"
            >
              {{ user.membershipName }}
            </span>
            <span v-if="isMembershipSwitchingEnabled">
              {{ label }}
            </span>
          </span>
          <template v-if="hasAction && isMembershipSwitchingEnabled">
            <base-icon
              svg="heroicons/outline/chevron-right"
              :size="24"
              class="ml-auto shrink-0"
            />
          </template>
        </div>
      </template>
    </component>
    <tier-modal
      v-if="isMembershipSwitchingEnabled"
      :visible="showTierModal"
      @hide="onTierModalHide"
    />
  </div>
</template>
